import { ArrowLeftOnRectangleIcon, ChatBubbleOvalLeftIcon, CircleStackIcon, CodeBracketIcon, FlagIcon, LightBulbIcon, PresentationChartLineIcon, PuzzlePieceIcon, SparklesIcon, WalletIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import landImage from '../public/images/land.png';
import Typed from 'typed.js';
import FadeInSection from '../components/FadeInSection';
import groundCubeImage from '../public/images/ground_cube.png';
import waterCubeImage from '../public/images/water_cube.png';
import { NextSeo } from 'next-seo';
import mushroomImage from '../public/images/mushroom.png';
import bauxiteImage from '../public/images/bauxite.png';
import fruitImage from '../public/images/fruit.png';
import cropsImage from '../public/images/crops.png';
import Link from 'next/link';
import ModalComponent from '../components/Modal';
import WalletLink from '../components/WalletLink';
import { signIn } from 'next-auth/react';
import { ExplorerApi } from 'atomicassets';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
const HomePage = () => {
  const typedEl = useRef(null);
  const typed = useRef(null);
  const [walletModalOpen, setWalletModalOpen] = useState(false);
  const [shareholders, setShareholders] = useState<{
    account: string;
    amount: number;
  }[]>([]);
  useEffect(() => {
    const options = {
      strings: ['Own NFT land', 'Work on players\' land', 'Earn in-game coins', 'Develop your land'],
      typeSpeed: 50,
      backSpeed: 50,
      loop: true,
      backDelay: 1500
    };
    typed.current = new Typed(typedEl.current, options);
    return () => {
      typed.current.destroy();
    };
  }, []);
  useEffect(() => {
    const api = new ExplorerApi('https://wax.api.atomicassets.io', 'atomicassets', {
      fetch
    });
    api.getAssets({
      template_whitelist: '712008,712013,712020',
      burned: false,
      limit: 1000
    }).then(assets => {
      const grouped = groupBy(assets, 'owner');
      const owners = [];
      for (const owner in grouped) {
        owners.push({
          account: owner,
          amount: grouped[owner].reduce((prev, curr) => {
            return prev + curr.data.units;
          }, 0)
        });
      }
      setShareholders(reverse(sortBy(owners.filter(o => !['apheliumgame', 'neftyblocksd'].includes(o.account)), 'amount')));
    });
  }, []);
  return <>
            <NextSeo title='The Discord Planet Where You Play, Engage, Earn!' description="NFT based Discord game. Own NFT land, work on other players' land, earn in-game coins and develop your land." openGraph={{
      url: 'https://aphelium.io',
      title: 'Aphelium | The Discord Planet Where You Play, Engage, Earn!',
      description: 'NFT based Discord game. Own NFT land, work on other players\' land, earn in-game coins and develop your land.',
      images: [{
        url: 'https://aphelium.io/images/land.png',
        width: 2048,
        height: 1217,
        alt: 'Aphelium land sample',
        type: 'image/png'
      }]
    }} />
            <section className="container mx-auto flex-col flex items-center gap-6 px-4 md:flex-row md:px-0 mt-16">
                <div className="w-full md:w-1/2">
                    <h1 className="text-4xl md:text-6xl text-white font-bold mb-3 leading-tight">
                        <span className="bg-clip-text bg-gradient-to-b from-[#f56969] to-[#b10404] text-transparent" ref={typedEl}></span><br />in the Aphelium<br />Metaverse
                    </h1>
                    {/* <p className="text-white text-base font-light">
                        Discord based bot game with Non Fungible Tokens involved
                     </p> */}
                </div>
                <div className="w-full md:w-1/2 flex justify-end relative">
                    <div className="animation-updown">
                        <Image src={landImage} alt="Aphelium land" />
                    </div>
                    {/* <div className="absolute -bottom-12 md:bottom-12 right-[50%] -mr-20 md:-mr-72 p-4 bg-emerald-700 text-white rounded-full w-36 h-36 text-center text-lg flex items-center justify-center">
                        <p className="mt-2">Whitelist open<br/>Get a <span className="underline">FREE</span> land!</p>
                     </div> */}
                </div>
            </section>
            <section className="container mx-auto grid grid-cols-1 gap-12 my-20 px-4 md:grid-cols-4 md:px-0">
                <div className="flex flex-col items-center border-t-4 gap-4 pt-4 border-purple-600 rounded-full">
                    <a href="https://discord.gg/hanatZPWFk" target="_blank" rel="noreferrer" title="Discord server invite" className="p-4 bg-purple-600 rounded-full flex items-center justify-center">
                        <svg className='w-12 h-12' fill="#ffffff" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Discord</title><path d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z" /></svg>
                    </a>
                    <p className="font-medium text-2xl text-white">Join the Discord server</p>
                    <p className="text-white text-center font-light">
            The bot is initially present in the Official Discord server only, join now to start playing!
                    </p>
                </div>
                <div className="flex flex-col items-center border-t-4 gap-4 pt-4 border-blue-600 rounded-full">
                    <button onClick={() => signIn('discord')} className="p-4 bg-blue-600 rounded-full flex items-center justify-center">
                        <ArrowLeftOnRectangleIcon className="text-white w-12 h-12" />
                    </button>
                    <p className="font-medium text-2xl text-white text-center">Login with your Discord account</p>
                    <p className="text-white text-center font-light">
            Log in our website using the Discord account you want to play with.
                    </p>
                </div>
                <div className="flex flex-col items-center border-t-4 gap-4 pt-4 border-orange-600 rounded-full">
                    <ModalComponent title="" isOpen={walletModalOpen}>
                        <WalletLink closeModal={setWalletModalOpen} />
                    </ModalComponent>
                    <button onClick={() => setWalletModalOpen(true)} className="p-4 bg-orange-600 rounded-full flex items-center justify-center">
                        <WalletIcon className="text-white w-12 h-12" />
                    </button>
                    <p className="font-medium text-2xl text-white">Connect your wallet</p>
                    <p className="text-white text-center font-light">
            Connect your wallet and link it with your Discord account.
                    </p>
                </div>
                <div className="flex flex-col items-center border-t-4 gap-4 pt-4 border-yellow-500 rounded-full">
                    <Link href={'/drops'} className="p-4 bg-yellow-500 rounded-full flex items-center justify-center">
                        <SparklesIcon className="text-white w-12 h-12" />
                    </Link>
                    <p className="font-medium text-2xl text-white">Get your shiny land</p>
                    <p className="text-white text-center font-light">
            Get your first land and start playing!
                    </p>
                </div>
            </section>
            <section className="bg-black py-4 px-4 md:px-0 md:py-12">
                <div className="container mx-auto flex-col flex items-center gap-8 px-4 md:flex-row">
                    <div className="w-full md:w-2/5">
                        <FadeInSection>
                            <div className="flex items-center w-full justify-center gap-4">
                                <div className="flex flex-1">
                                    <div className="mt-32 bg-slate-600 p-2 rounded-3xl hover:outline outline-offset-8 hover:outline-2 outline-water-500 transition-all duration-200">
                                        <Image src={groundCubeImage} alt="Ground cube" />
                                    </div>
                                </div>
                                <div className="flex flex-1">
                                    <div className="bg-slate-700 p-2 rounded-3xl hover:outline outline-offset-8 hover:outline-2 outline-water-500 transition-all duration-200">
                                        <Image src={waterCubeImage} alt="Water cube" />
                                    </div>
                                </div>
                            </div>
                        </FadeInSection>
                    </div>
                    <div className="w-full md:w-3/5">
                        <FadeInSection>
                            <h2 className="text-2xl relative text-white mb-4 font-medium">
                                <span className="border-2 border-water-500 rounded-full leading-loose px-6 py-1">Land NFT</span>
                            </h2>
                            <h3 className="text-5xl text-white font-bold uppercase mb-4">A harmonious mix of cubes</h3>
                            <p className="text-lg font-medium text-white mb-4 border-l-4 border-water-500 pl-4 py-2">
                The lands are <span className="font-black">NFTs</span> on the WAX blockchain and can be purchased on AtomicHub or NeftyBlocks. The lands and resources are created exclusively for Aphelium and are hand drawn by our in-house graphic designer.
                            </p>
                            <p className="text-md font-light text-white mb-1 tracking-wide">
                The lands are made up of cubes of ground and water placed randomly to form an image of <span className="font-bold">2048x2048 pixels</span>. The cubes of ground and water will be selected with <span className="font-bold">random percentages</span> to prevent players finding lands with the same ratio.
                            </p>
                            <p className="text-md font-light text-white mb-1 tracking-wide">
                It will be possible to find land without water, but currently you will not find land without ground. As for now there is only one aquatic resource, and the absence of land would prevent construction of ground-based buildings. All of the <span className="font-bold">buildings</span> you own in your Aphelian lands will not be attached to the ground, but will hover above it through magnetism, they will be floating buildings!
                            </p>
                        </FadeInSection>
                    </div>
                </div>
            </section>
            <section className="bg-black py-4 px-4 md:px-0 md:py-12">
                <div className="container mx-auto flex-col flex items-center gap-8 px-4 md:flex-row">
                    <div className="w-full md:w-2/3">
                        <FadeInSection>
                            <p className="text-2xl relative text-white mb-4 font-medium">
                                <span className="border-2 border-water-500 rounded-full leading-loose px-6 py-1">Resources</span>
                            </p>
                            <p className="text-5xl text-white font-bold uppercase mb-4">Unique resources</p>
                            <p className="text-md font-light text-white mb-1 tracking-wide">
                                Lands come with a set of resources that other players can extract for you working on your land.<br />
                                Every resource has his own use and will help you to survive on this unexplored planet.
                            </p>
                            <p className="text-lg font-medium text-white mb-1 border-l-4 border-water-500 pl-4 py-2">
                                Trade, craft, eat. Get out the most of your resources and you will conquer the planet!
                            </p>
                        </FadeInSection>
                    </div>
                    <div className="w-full md:w-1/3">
                        <FadeInSection>
                            <div className="flex items-center w-full justify-center gap-4">
                                <div className="flex flex-1">
                                    <div className="mt-16 bg-slate-600 p-2 rounded-3xl hover:outline outline-offset-8 hover:outline-2 outline-water-500 transition-all duration-200">
                                        <Image src={mushroomImage} alt="Aphelim Mushrooms" />
                                    </div>
                                </div>
                                <div className="flex flex-col flex-1">
                                    <div className="mt-6 bg-slate-800 p-2 rounded-3xl hover:outline outline-offset-8 hover:outline-2 outline-water-500 transition-all duration-200">
                                        <Image src={bauxiteImage} alt="Aphelim Bauxite" />
                                    </div>
                                    <div className="mt-6 bg-slate-700 p-2 rounded-3xl hover:outline outline-offset-8 hover:outline-2 outline-water-500 transition-all duration-200">
                                        <Image src={fruitImage} alt="Aphelim Fruits" />
                                    </div>
                                </div>
                                <div className="flex flex-1">
                                    <div className="-mt-16 bg-slate-900 p-2 rounded-3xl hover:outline outline-offset-8 hover:outline-2 outline-water-500 transition-all duration-200">
                                        <Image src={cropsImage} alt="Aphelim Crops" />
                                    </div>
                                </div>
                            </div>
                        </FadeInSection>
                    </div>
                </div>
            </section>
            <section className="container mx-auto py-12 px-6 md:px-0">
                <FadeInSection>
                    <h2 className="text-white text-6xl font-bold mb-10 uppercase">Roadmap</h2>
                    <ol className="items-center justify-between sm:flex">
                        <li className="relative mb-6 sm:mb-0">
                            <div className="flex items-center">
                                <div className="flex z-10 justify-center items-center w-8 h-8 bg-water-500 rounded-full ring-8 ring-gray-700 shrink-0">
                                    <LightBulbIcon className="text-white w-5 h-5" />
                                </div>
                                <div className="hidden sm:flex w-full bg-gray-700 h-0.5"></div>
                            </div>
                            <div className="mt-3 sm:pr-8">
                                <h3 className="text-lg font-base text-white">Initial idea</h3>
                                <time className="block mb-2 text-sm font-normal leading-none text-gray-500">End of 2021</time>
                                {/* <p className="text-base font-normal text-gray-400">The Discord game concept is born, started gathering information about how to make NFTs work in a Discord bot</p> */}
                            </div>
                        </li>
                        <li className="relative mb-6 sm:mb-0">
                            <div className="flex items-center">
                                <div className="flex z-10 justify-center items-center w-8 h-8 bg-water-500 rounded-full ring-gray-700 shrink-0 ring-8">
                                    <CodeBracketIcon className="text-white w-5 h-5" />
                                </div>
                                <div className="hidden sm:flex w-full bg-gray-700 h-0.5"></div>
                            </div>
                            <div className="mt-3 sm:pr-8">
                                <h3 className="text-lg font-base text-white">Start development</h3>
                                <time className="block mb-2 text-sm font-normal leading-none text-gray-500">Q3 2022</time>
                                {/* <p className="text-base font-normal text-gray-400">Started the development of the smart contracts and the initial skeleton of the bot, followed by some parts of the dApp</p> */}
                            </div>
                        </li>
                        <li className="relative mb-6 sm:mb-0">
                            <div className="flex items-center">
                                <div className="flex z-10 justify-center items-center w-8 h-8 bg-water-500 rounded-full ring-gray-700 shrink-0 ring-8">
                                    <ChatBubbleOvalLeftIcon className="text-white w-5 h-5" />
                                </div>
                                <div className="hidden sm:flex w-full bg-gray-700 h-0.5"></div>
                            </div>
                            <div className="mt-3 sm:pr-8">
                                <h3 className="text-lg font-base text-white">Server creation and initial marketing</h3>
                                <time className="block mb-2 text-sm font-normal leading-none text-gray-500">Q4 2022</time>
                                {/* <p className="text-base font-normal text-gray-400">Created and set up our Discord server and started server promotion</p> */}
                            </div>
                        </li>
                        <li className="relative mb-6 sm:mb-0">
                            <div className="flex items-center">
                                <div className="flex z-10 justify-center items-center w-8 h-8 bg-water-500 rounded-full ring-gray-100 shrink-0 ring-8">
                                    <PuzzlePieceIcon className="text-white w-5 h-5" />
                                </div>
                                <div className="hidden sm:flex w-full bg-gray-700 h-0.5"></div>
                            </div>
                            <div className="mt-3 sm:pr-8">
                                <h3 className="text-lg font-bold text-white uppercase">Demo bot</h3>
                                <time className="block mb-2 text-sm font-normal leading-none text-gray-500">Dec 2022</time>
                                {/* <p className="text-base font-normal text-gray-400">Demo bot release with limited functionalities to test the gameplay and get your hands dirty</p> */}
                            </div>
                        </li>
                        <li className="relative mb-6 sm:mb-0">
                            <div className="flex items-center">
                                <div className="flex z-10 justify-center items-center w-8 h-8 bg-water-500 rounded-full ring-gray-100 shrink-0 ring-8">
                                    <FlagIcon className="text-white w-5 h-5" />
                                </div>
                                <div className="hidden sm:flex w-full bg-gray-700 h-0.5"></div>
                            </div>
                            <div className="mt-3 sm:pr-8">
                                <h3 className="text-lg font-bold text-white uppercase">Land sale and Beta release</h3>
                                <time className="block mb-2 text-sm font-normal leading-none text-gray-500">6th July 2023</time>
                            </div>
                        </li>
                        <li className="relative mb-6 sm:mb-0">
                            <div className="flex items-center">
                                <div className="flex z-10 justify-center items-center w-8 h-8 bg-water-500 rounded-full ring-gray-700 shrink-0 ring-8">
                                    <PresentationChartLineIcon className="text-white w-5 h-5" />
                                </div>
                                <div className="hidden sm:flex w-full bg-gray-700 h-0.5"></div>
                            </div>
                            <div className="mt-3 sm:pr-8">
                                <h3 className="text-lg font-base text-white">Marketing campaign</h3>
                                <time className="block mb-2 text-sm font-normal leading-none text-gray-500">Q2/Q3 2023</time>
                            </div>
                        </li>
                        <li className="relative mb-6 sm:mb-0">
                            <div className="flex items-center">
                                <div className="flex z-10 justify-center items-center w-8 h-8 bg-water-500 rounded-full ring-gray-100 shrink-0 ring-8">
                                    <CircleStackIcon className="text-white w-5 h-5" />
                                </div>
                                <div className="hidden sm:flex w-full bg-gray-700 h-0.5"></div>
                            </div>
                            <div className="mt-3 sm:pr-8">
                                <h3 className="text-lg font-bold text-white uppercase">Token release</h3>
                                <time className="block mb-2 text-sm font-normal leading-none text-gray-500">End 2024</time>
                            </div>
                        </li>
                    </ol>
                </FadeInSection>
            </section>
            <section className="bg-black py-4 px-4 md:px-0 md:py-12">
                <div className="container mx-auto py-12 px-6 md:px-0">
                    <FadeInSection>
                        <h2 className="text-white text-6xl font-bold mb-10 uppercase">Partners</h2>
                        <div className="flex gap-12">
                            <Link href={'https://discord.gg/XUmzVruwNk'} target='_blank' rel="nofollow" className="rounded-full overflow-hidden w-52 hover:scale-110 transition-transform duration-300">
                                <Image src={'/images/partners/himoon.png'} width={500} height={500} alt="Hi-Moon Gamefi" />
                            </Link>
                        </div>
                    </FadeInSection>
                </div>
            </section>
            <section className="container mx-auto py-12 px-6 md:px-0">
                <FadeInSection>
                    <h2 className="text-white text-5xl md:text-6xl font-bold mb-10 uppercase">Share Holders</h2>
                    <div className="flex flex-wrap gap-12 justify-center md:justify-start">
                        {shareholders.map(sh => <div className="w-32 flex flex-col items-center gap-2" key={sh.account}>
                                {/* eslint-disable-next-line @next/next/no-img-element */}
                                <img alt="WAX profile picture" className={`rounded-full bg-gray-700 border-4 ${sh.amount >= 1000 ? 'border-yellow-500' : sh.amount >= 100 ? 'border-gray-400' : 'border-amber-800'}`} src={`https://profile.api.atomichub.io/v1/profiles/chain/wax-mainnet/account/${sh.account}/avatar?skip_hash=true`} />
                                <p>{sh.account}</p>
                            </div>)}
                    </div>
                </FadeInSection>
            </section>
        </>;
};
export default HomePage;